import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import ReactMarkdown from "react-markdown"
import Seo from "../components/SEO"

const ComponentName = ({data}) => {
  const {content, title, desc} = data.blog;

  return (
      <Layout>
          <Seo title={title} description={desc}/>
          <section className="blog-template">
            <div className="section-center">
                <article className="blog-content">
                    
                    <ReactMarkdown children={content} />
                </article>
                <Link to="/blog" className="btn center-btn">blog</Link>
            </div>
          </section>
      </Layout>
  )
}

export const query = graphql`
  query GetSingleBlog($slug: String) {
    blog: strapiBlogs(slug: { eq: $slug }) {
      content
      title
      desc
    }
  }
`

export default ComponentName;